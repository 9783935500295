import { Box } from "@mui/material"
import { navigate } from "gatsby"
import React from "react"
import { MainLayout } from "../common/MainLayout"

export type MetamaskProps = {}

export const Metamask: React.FunctionComponent<MetamaskProps> = () => {
  return (
    <MainLayout>
      <div className="c-feature">
        <div className="c-feature__ttl">
          <h3 className="c-feature__ttl--01">MetaMaskの使い方</h3>
          <p className="c-feature__ttl--02">
            （※MetaMaskとは、仮想通貨を保管できるウォレットです。）
          </p>
        </div>
      </div>
      <div className="c-main">
        <div className="c-container mx-auto">
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            MetaMaskのインストール
          </Box>
          <div className="c-content">
            <p className="c-content__txt pb">
              MetaMaskの公式サイトにアクセスし、「Download for
              Chrome(ロゴ)」をクリックします。
              <br />
              (Chrome拡張機能のダウンロードページヘ移ります)
            </p>
            <div className="c-block">
              <div className="c-block__img">
                <img src="/images/metamask/img-13.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                ダウンロードページに進んだら、画面右にある「Chromeに追加」をクリックしてしてください。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-14.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <div className="c-block__img">
                <img src="/images/metamask/img-15.png" alt="" />
              </div>
            </div>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            MetaMaskの初期設定
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「開始」をクリックします。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-16.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                初めてメタマスクを作成する場合は、「ウォレットを作成」をクリックします。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-17.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                品質向上に合意する画面が出るので「同意する」をクリックします。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-18.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                パスワードを入力して、利用規約にチェックして作成ボタンをクリックます。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-19.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                リカバリーフレーズとウォレットを安全に保つ方法についての動画が表示されるので、
                <br />
                動画を観たら「次へ」をクリックします。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-20.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「パスフレーズ」の確認および保存を行います。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-21.png" alt="" />
              </div>
              <p className="c-block__ttl">
                「パスフレーズ」の確認方法は、鍵のマークをクリックすると表示されます。
              </p>
              <Box
                className="c-block__frame"
                sx={{ borderColor: "primary.main" }}
              >
                <p className="c-block__frame--01">「パスフレーズ」の保管方法</p>
                <div className="c-block__frame--02">
                  <p>
                    <span className="dot">●</span>
                    <span>紙に書く</span>
                  </p>
                  <p>
                    <span className="dot">●</span>
                    <span>
                      スクリーンショットを取り、印刷したものを保管する
                    </span>
                  </p>
                  <p>
                    <span className="dot">●</span>
                    <span>
                      内容をコピーし、自分のパスワード管理ソフト等で管理する
                    </span>
                  </p>
                  <p>
                    などがあります。ご自身に合った方法で保管するようにしてください。
                  </p>
                </div>
              </Box>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「パスフレーズ」を入力します。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-22.png" alt="" />
              </div>
              <div className="c-block__img">
                <img src="/images/metamask/img-23.png" alt="" />
              </div>
              <p className="c-block__ttl">
                入力の注意点として、単語の順番も表示通りに入力する必要があります。
                <br />
                「パスフレーズ」入力完了後「すべて完了」をクリックします。
              </p>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                初期設定は以上で完了です
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-24.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                ウォレットが使えるようになりました
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-25.png" alt="" />
              </div>
            </div>
            <Box
              className="c-btn cursor-pointer"
              sx={{ backgroundColor: "primary.main" }}
              onClick={() => navigate("/buy_eth")}
            >
              ETH入金方法はこちら
            </Box>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            ログイン、ログアウト、アドレスの確認など基本的な使い方
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                Google Chrome拡張機能のアイコンをクリックします。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-26.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                ポップアップが開くので、パスワードを入力し「ロック解除」をクリックします。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-27.png" alt="" />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                画面全体にメタマスクを表示した場合
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-28.png" alt="" />
              </div>
            </div>
            <Box
              className="c-block__frame"
              sx={{ borderColor: "primary.main" }}
            >
              <p className="c-block__frame--03">
                ポップアップではなく、画面全体にメタマスクを表示した場合は、右上の3点マークをクリックして、「ビューの展開」をクリックすれば、表示に切り替えることができます。
              </p>
            </Box>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                ログアウト時は、画面右上のアイコンをクリックして、「ロック」ボタンをクリックすればOKです。
              </Box>
              <div className="c-block__img">
                <img src="/images/metamask/img-29.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
